'use client';

import React, { ReactNode, useContext, useEffect } from 'react';
import LenisWrapper from '@/providers/scroll/LenisWrapper';
import { ThemeContext } from '@/providers/theme/theme-provider';

const Body = ({ className, children }: { className: string; children: ReactNode }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <LenisWrapper>
      <body className={className} data-theme={theme}>
        {children}
      </body>
    </LenisWrapper>
  );
};

export default Body;
